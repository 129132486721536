import { Box, useTheme } from "@mui/material";
import Modal from "react-modal";
import FullCalendar  from '@fullcalendar/react'
import { formatDate } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import {
  AddTreatment,
  Addscan,
  deletescan,
  getscan,

} from "../../Redux/Treatment/TreatmentActions";
import moment from "moment";
import { getPatientStatistic,getCalendardaily,getDateCalendar } from "../../Redux/Patient/PatientActions";
import Header from "../../components/Header";
import Loading from "../../components/Loading";
import { tokens } from "../../theme";
import Sidebar from "../global/Sidebar";
import Topbar from "../global/Topbar";
import "./../Style.css";
import { getScanner } from "../../Redux/Scanner/ScannerActions";
import { useNavigate } from "react-router-dom";
const Treatment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [record, setrecord] = useState({});
  const { Scanner } = useSelector((state) => state.Scanner);
  const { patientStatistic,calendar ,dateincalendar} = useSelector(
    (state) => state.Patient
  );

  const { scan, loading } = useSelector((state) => state.Treatment);
  useEffect(() => {
    dispatch(getscan(id));
    dispatch(getScanner());
    dispatch(getPatientStatistic(id));
    dispatch(getCalendardaily(id));
  }, []);
  const [selected, setSelected] = useState(moment());

  useEffect(() => {
    if (patientStatistic) {
      setStatData(patientStatistic?.data?.alignersStats);
    }
  }, [patientStatistic]);
  useEffect(() => {
    dispatch(getDateCalendar(id,moment(selected).format("YYYY-MM-DD")));
  }, [dispatch, selected]);
  useEffect(() => {
    setrecord(calendar?.data?.daily_check);
  }, [calendar]);
  const markedDates = [];
  for (const date in record) {
    if (record[date]) {
      markedDates.push({
        start: date,
        display: 'background',
        backgroundColor: record[date],
      });
    }
  }
  const [data, setData] = useState([]);
  const [Id, setId] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [type, settype] = useState("");
  const [scannerId, setScannerId] = useState("");
  const [statdata , setStatData] = useState();
  const location = useLocation();
  //   const receivedData = location.state;
  const queryParams = new URLSearchParams(location.search);
  const name = queryParams.get("name");

  let firstName = "";
  let lastName = "";

  if (name) {
    const nameParts = name.split(" ");
    firstName = nameParts[0];
    lastName = nameParts.slice(1).join(" ");
  }
  useEffect(() => {
    if (scan) {
      setData(scan?.data?.files);
    }
  }, [scan]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [isSidebar, setIsSidebar] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpen1, setModalIsOpen1] = useState(false);
  const [modalstatIsOpen, setModalstatIsOpen] = useState(false);
  const [modalCalanderIsOpen, setModalCalanderIsOpen] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);

  const [file, setFile] = useState(null);
  const [Images, setImages] = useState(null);

 
  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };

  const openCalanderModal = () => {
    setModalCalanderIsOpen(true);
  };
  const closeCalanderModal = () => {
    setModalCalanderIsOpen(false);
  };
  const openstatModal = () => {
    setModalstatIsOpen(true);
  };
  const closestatModal = () => {
    setModalstatIsOpen(false);
  };

  const opendeleteModal = () => {
    setDeleteModal(true);
  };
  const closedeleteModal = () => {
    setDeleteModal(false);
  };

  const openModal1 = () => {
    setModalIsOpen1(true);
  };
  const closeModal1 = () => {
    setModalIsOpen1(false);
  };
  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFile((prevFiles) =>
      prevFiles ? [...prevFiles, ...selectedFiles] : selectedFiles
    );
  };

  const handleImageChange = (event) => {
    const selectedImages = Array.from(event.target.files);
    setImages((prevImages) =>
      prevImages ? [...prevImages, ...selectedImages] : selectedImages
    );
  };

  const handleUpload = () => {
    dispatch(Addscan(id, file, scannerId));
    closeModal();
    setFile(null);
  };
  const handleUpload1 = () => {
    navigate(`/Treatment-plan/${id}`);
  };

  const downloadFile = async (fileName) => {
    try {
      axios({
        url:
          process.env.REACT_APP_API +
          "/api/patients/scan/download/" +
          id +
          "/" +
          fileName,
        method: "GET",
        responseType: "blob", // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      });
    } catch (error) {
      console.error("Error downloading file:", error);
      // Handle error, e.g., show an error message to the user
    }
  };

  const columns = [
    {
      field: "File Type",
      headerName: "File Type",
      flex: 1,
      renderCell: (record) => {
        return (
          <Fragment>
            <div variant="outlined" color="primary">
              {record.row.type}{" "}
            </div>
          </Fragment>
        );
      },
    },
    {
      field: "File Name",
      headerName: "File Name",
      flex: 1,
      renderCell: (record) => {
        return (
          <Fragment>
            <div variant="outlined" color="primary">
              {record.row.name}{" "}
            </div>
          </Fragment>
        );
      },
    },
    {
      field: "Image",
      headerName: "Image",
      flex: 1,
      renderCell: (record) => {
        return (
          <Fragment>
            <img
              src={process.env.REACT_APP_API + record.row.url}
              alt="file"
              style={{ width: "100px", height: "100px" }}
            />
          </Fragment>
        );
      },
    },
    {
      field: "Action",
      headerName: "Action",
      flex: 1,
      renderCell: (record) => {
        return (
          <Fragment>
            <button
              variant="outlined"
              color="primary"
              onClick={() => {
                downloadFile(record.row.name);
              }}
            >
              download{" "}
            </button>
            &nbsp; &nbsp;
            <button
              data-toggle="modal"
              data-target="#delete-carousel-modal"
              className="actions1"
              onClick={() => {
                setId(record.row.id);
                setFileName(record.row.name);
                opendeleteModal();
              }}
            >
              Delete
            </button>
          </Fragment>
        );
      },
    },
  ];
  const deletehandle = (id, fileName) => {
    dispatch(deletescan(id, fileName));
    closedeleteModal();
  };

  return (
    <div className="app">
      <Sidebar isSidebar={isSidebar} />
      <main className="content">
        <Topbar setIsSidebar={setIsSidebar} />

        <Box m="20px">
          <Modal
            isOpen={modalIsOpen1}
            onRequestClose={closeModal1}
            shouldCloseOnOverlayClick={false}
            contentLabel="add modal"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                width: "40rem",
                height: "15rem",
                margin: "0 auto",
                padding: "10px",
                background: "white",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                zIndex: 1000,
              },
            }}
          >
            <div>
              <h2 className="modaltitle">Add Images</h2>
              <input
                type="file"
                accept="image/*"
                multiple
                onChange={handleImageChange}
              />
            </div>
            <div
              style={{
                height: "4rem",
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <button onClick={closeModal1} className="buttoninmodal">
                Close
              </button>
              <button
                onClick={() => handleUpload1()}
                className="buttoninmodal1"
              >

                Add Image
              </button>
            </div>
          </Modal>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            shouldCloseOnOverlayClick={false}
            contentLabel="delete modal"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                width: "40rem",
                height: "15rem",
                margin: "0 auto",
                padding: "10px",
                background: "white",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                zIndex: 1000,
              },
            }}
          >
            <div>
              <h2 className="modaltitle">Add File</h2>

              <input
                type="file"
                accept="application/*"
                onChange={handleFileChange}
                multiple
              />
              <div className="modal-body modalview">
                <div className="row formRow inputstyle">
                  <h6>Scanner :</h6>

                  <select
                    onChange={(e) => setScannerId(e.target.value)}
                    required
                    defaultValue={scannerId}
                    className="selectg"
                  >
                    <option value="" className="option">
                      Please select Scanner
                    </option>
                    {Scanner?.data?.scanners?.map((brands) => (
                      <option key={brands.id} value={brands.id}>
                        {brands.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div
              style={{
                height: "4rem",
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <button onClick={closeModal} className="buttoninmodal">
                Close
              </button>
              <button onClick={() => handleUpload()} className="buttoninmodal1">
                Add File
              </button>
            </div>
          </Modal>
          <Modal
            isOpen={modalCalanderIsOpen}
            onRequestClose={closeCalanderModal}
            shouldCloseOnOverlayClick={false}
            contentLabel="Calander"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                width: "40rem",
                height: "40vh",
                margin: "0 auto",
                padding: "10px",
                background: "white",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                zIndex: 1000,
              },
            }}
          >
            <div>
            <FullCalendar
                height="20vh"
                plugins={[
                  dayGridPlugin,
                  timeGridPlugin,
                  interactionPlugin,
                  listPlugin,
                ]}
                headerToolbar={{
                  left: "prev,next today",
                  center: "title",
                  right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
                }}
                initialView="dayGridMonth"
                editable={true}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                events={markedDates}
                dateClick={(arg) => setSelected(arg.dateStr)}              
              />
        <div className="scroll-date">
      <p className="date title">Wore Time</p>
      {dateincalendar?.data?.alignerSessions?.date?.length > 0 ? (
        dateincalendar.data.alignerSessions.date.map((item, index) => (
          <p key={index} className="date">
            {moment(item?.start_date).format("HH:mm:ss")} -{" "}
            {moment(item?.end_date).format("HH:mm:ss")}
          </p>
        ))
      ) : (
        <p className="date">No Data</p>
      )}
    </div>
              </div>
            <div
              style={{
                height: "4rem",
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <button onClick={closeCalanderModal} className="buttoninmodal">
                Close
              </button>
            
            </div>
          </Modal>
          <Modal
            isOpen={deleteModal}
            onRequestClose={closedeleteModal}
            shouldCloseOnOverlayClick={false}
            contentLabel="delete modal"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                width: "40rem",
                height: "15rem",
                margin: "0 auto",
                padding: "10px",
                background: "white",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                zIndex: 1000,
              },
            }}
          >
            <div>
            <h2 className="modaltitle">Delete File</h2>
            <h5>Are You Sure You Want To Delete This File ?</h5>
            </div>
            <div
              style={{
                height: "4rem",
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <button onClick={closedeleteModal} className="buttoninmodal">
                Close
              </button>
              <button
                onClick={() => deletehandle(id, fileName)}
                className="buttoninmodal1"
              >
                Delete
              </button>
            </div>
          </Modal>
          <Modal
            isOpen={modalstatIsOpen}
            onRequestClose={closestatModal}
            shouldCloseOnOverlayClick={false}
            contentLabel="Statistic modal"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                width: "40rem",
                height: "20rem",
                margin: "0 auto",
                padding: "10px",
                background: "white",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                zIndex: 1000,
              },
            }}
          >            <h2 className="modaltitle">Statistic</h2>

          <div>
          {statdata && Object.entries(statdata).map(([key, value], i) => (
  <div className="modal-body modalview" key={i}>
    <div className=" formRow inputstyle">
      <h6>Aligner {key} : </h6>
      <p>{value}</p> {/* Display the value for that aligner */}
    </div>
  </div>
))}


</div>

            <div
              style={{
                height: "4rem",
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <button onClick={closestatModal} className="buttoninmodal">
                Close
              </button>
       
            </div>
          </Modal>
          <Header
            title={
              firstName + " " + lastName + "'s " + "Treatment and scan files"
            }
            buttonText="Add Scan"
            onopen={openModal}
            buttonText1="Add Plan"
            onopen1={handleUpload1}
          />
          <div className="end-line">
          <button
              style={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
              }}
              onClick={openCalanderModal}
            >
              Check Patient Calander
            </button>
            <button
              style={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
              }}
              onClick={openstatModal}
            >
              Check Patient Statistic
            </button>
          </div>

          <Box
            m="40px 0 0 0"
            height="75vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                zIndex: 0,
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
            }}
          >
            {loading && <Loading />}
            {!loading && data && (
              <DataGrid
                rows={data}
                columns={columns}
                components={{ Toolbar: GridToolbar }}
                sx={{
                  "& .MuiDataGrid-cell": {
                    fontSize: "16px", // Adjust the font size as needed
                    color: "black",
                  },
                  "& .MuiDataGrid-columnHeader": {
                    fontSize: "16px",
                    color: "black",
                    fontWeight: "bold", // Adjust the font size as needed
                  },
                }}
                checkboxSelection={true}
              />
            )}
          </Box>
        </Box>
      </main>
    </div>
  );
};

export default Treatment;
