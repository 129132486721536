import axios from "axios";
import { ScannerAction } from "./ScannerReducers";

export const getScanner = () => async (dispatch) => {
  const token = localStorage.getItem("jwtToken");

  try {
    dispatch(ScannerAction.ScannerRequest());

    const res = await axios.get(process.env.REACT_APP_API + "/api/scanners",
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );;

    dispatch(ScannerAction.ScannerSuccess(res));
  } catch (error) {
    dispatch(ScannerAction.ScannerFail(error));
  }
};

export const addScanner =
  (name, serial_number,clinic_id) =>
  async (dispatch) => {
    dispatch(ScannerAction.addScannerRequest());
    const token = localStorage.getItem("jwtToken");

    const formData = new FormData();
    formData.append("name", name);
    formData.append("serial_number", serial_number);
    formData.append("clinic_id", clinic_id);

    try {
      await axios.post(
        process.env.REACT_APP_API + "/api/scanners",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      dispatch(ScannerAction.addScannerSuccess());
      dispatch(getScanner());
    } catch (e) {
      dispatch(ScannerAction.addScannerFail(e.response.data));
    }
  };

export const updateScanner =
  (id, name, serial_number,clinic_id) =>
  async (dispatch) => {
    dispatch(ScannerAction.updateScannerRequest());
    const token = localStorage.getItem("jwtToken");

  

    try {
      await axios.put(
        process.env.REACT_APP_API + "/api/scanners/" + id,
        { name, serial_number ,clinic_id},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(ScannerAction.updateScannerSuccess());
      dispatch(getScanner());
    } catch (e) {
      dispatch(ScannerAction.updateScannerFail(e.response.data));
    }
  };

export const deleteScanner = (id) => async (dispatch) => {
  dispatch(ScannerAction.deleteScannerRequest());
  const token = localStorage.getItem("jwtToken");
  try {
    await axios.delete(process.env.REACT_APP_API + "/api/scanners/" + id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(ScannerAction.deleteScannerSuccess());
    dispatch(getScanner());
  } catch (e) {
    dispatch(ScannerAction.deleteScannerFail(e.response.data));
  }
};
