import { Typography, Box, useTheme, Button } from "@mui/material";
import { tokens } from "../theme";
import "./../scenes/Style.css";

const Header = ({ title, subtitle, buttonText, onopen, show ,showboth , buttonText1 , onopen1}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mb="30px"
    >
      <Typography
        variant="h2"
        color={colors.grey[100]}
        fontWeight="bold"
        sx={{ m: "0 0 5px 0" }}
      >
        {title}
      </Typography>
<div
style={{
display: "flex",
flexDirection: "row",
alignItems: "center",
gap: "10px",
}
}
>
      {!show && (
        <button className="addbutton" onClick={onopen}>
          {buttonText}
        </button>
      )}
        {!showboth && (
        <button className="addbutton" onClick={onopen1}>
          {buttonText1}
        </button>
      )}
      </div>
    </Box>
  );
};

export default Header;
