import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  designer: [],
  addDesigner: [],
  updateDesigner: [],
  deleteDesigner: [],
};

const DesignerSlice = createSlice({
  name: "designer",
  initialState,
  reducers: {
    designerRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    designerSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.designer = action.payload;
    },

    designerFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    addDesignerRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    addDesignerSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.addDesigner = action.payload;
    },

    addDesignerFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateDesignerRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    updateDesignerSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.updateDesigner = action.payload;
    },

    updateDesignerFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteDesignerRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    deleteDesignerSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.deleteDesigner = action.payload;
    },

    deleteDesignerFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const DesignerAction = DesignerSlice.actions;
export default DesignerSlice;
