import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import {
  getTeam,
  AddTeammember,
  deleteadmin,
  // updateplan,
  // Addplan,
} from "../../Redux/Team/TeamActions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, Fragment } from "react";
import Loading from "../../components/Loading";
import "./../Style.css";
import Modal from "react-modal";
import Topbar from "./../global/Topbar";
import Sidebar from "./../global/Sidebar";
import { useNavigate } from "react-router-dom";
import isAuth from "../../scenes/Utils/isAuth";
import { getDistrict } from "../../Redux/District/DistrictActions";

const Admin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/login");
  }, [dispatch, navigate]);
  const { Team, loading } = useSelector((state) => state.Team);
  useEffect(() => {
    dispatch(getTeam());
    dispatch(getDistrict());

  }, []);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (Team) {
      // Filter the users based on user_type_id equal to 1
      const filteredUsers = Team?.data?.users?.filter(
        (user) => user.user_type_id === 1 && user.email != localStorage.getItem("email")
      );

      // Set the filtered data
      setData(filteredUsers);
    }
  }, [Team]);
  const [district_id, setDistrict_id] = useState(1);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [id, setId] = useState("");
  const [phonenumber,setPhonenumber] =useState("");
  const [email,setEmail] =useState("");

  const { District } = useSelector((state) => state.District);

  const deleteplanhandle = (id) => {
    dispatch(deleteadmin(id));
    setModalIsOpen(false);
    setId("");
  };
  const [isSidebar, setIsSidebar] = useState(true);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const [addmodalIsOpen, setaddModalIsOpen] = useState(false);

  const addopenModal = () => {
    setaddModalIsOpen(true);
  };

  const addcloseModal = () => {
    setaddModalIsOpen(false);
    setedutModalIsOpen(false);
 
  };
  const addplanhandle = (
   email,
   phonenumber,
   district_id
  ) => {
    dispatch(
      AddTeammember(
        email,
        phonenumber,
        1,
      )
    );
    setaddModalIsOpen(false);
  setDistrict_id(0);
  setEmail("");
  setPhonenumber("")
  };

  const [edutmodalIsOpen, setedutModalIsOpen] = useState(false);

  const editopenModal = () => {
    setedutModalIsOpen(true);
  };


  const columns = [
    { field: "email", headerName: "Email", width: 200 },
    {
      field: "phone_number",
      headerName: "Phone number",
      width: 200,
    },

    {
      field: "action",
      headerName: "Action",
      flex: 1,
      width: 100,
      renderCell: (record) => {
        return (
          <Fragment>
       {localStorage.getItem("role") == "superadmin"  &&    <button
              data-toggle="modal"
              data-target="#delete-carousel-modal"
              className="actions1"
              onClick={() => {
                setId(record.row.id);
                openModal();
              }}
            >
              Delete
            </button>}
          </Fragment>
        );
      },
    },
  ];

  return (
    <div className="app">
      <Sidebar isSidebar={isSidebar} />
      <main className="content">
        <Topbar setIsSidebar={setIsSidebar} />

        <Box m="20px">
    
          <Modal
            isOpen={addmodalIsOpen}
            onRequestClose={addcloseModal}
            shouldCloseOnOverlayClick={false}
            contentLabel="Add modal"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                width: "40%",

                margin: "0 auto",
                padding: "10px",
                background: "white",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                zIndex: 1000,
              },
            }}
          >
            <div>
              <h2 className="modaltitle">Add Admin</h2>
              <form>
                <div className="modal-body modalview">
                  <div className="row formRow inputstyle">
                    <h6>Email:</h6>
                    <input
                      type="text"
                      className="  inputt col-md-8 form-control cat"
                      value={email}
                      placeholder="Enter the Email"
                      required
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </div>

                  <div className="row formRow inputstyle">
                    <h6>Phone Number:</h6>
                    <input
                      type="text"
                      className="  inputt col-md-8 form-control cat"
                      value={phonenumber}
                      placeholder="Enter the Phone Number"
                      required
                      onChange={(e) => {
                        setPhonenumber(e.target.value);
                      }}
                    />
                  </div>
             
                </div>
              </form>
            </div>
            <div
              style={{
                height: "4rem",
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
                marginTop: "20px",
              }}
            >
              <button onClick={addcloseModal} className="buttoninmodal">
                Close
              </button>
              <button
                onClick={() =>
                  addplanhandle(
                    email,
                    phonenumber,
                    district_id
                  )
                }
                className="buttoninmodal1"
              >
                Add Admin
              </button>
            </div>
          </Modal>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            shouldCloseOnOverlayClick={false}
            contentLabel="delete modal"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                width: "40rem",
                height: "15rem",
                margin: "0 auto",
                padding: "10px",
                background: "white",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                zIndex: 1000,
              },
            }}
          >
            <div>
              <h2 className="modaltitle">Delete Admin</h2>
              <h5>Are You Sure You Want To Delete This Admin ?</h5>
            </div>
            <div
              style={{
                height: "4rem",
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <button onClick={closeModal} className="buttoninmodal">
                Close
              </button>
              <button
                onClick={() => deleteplanhandle(id)}
                className="buttoninmodal1"
              >
                Delete
              </button>
            </div>
          </Modal>
          <Header title="Admin" buttonText="Add Admin" onopen={addopenModal} showboth={true}/>
          <Box
            m="40px 0 0 0"
            height="75vh"
            sx={{
            "& .MuiDataGrid-root": {
                border: "none",
                zIndex: 0,
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
            }}
          >
            {loading && <Loading />}
            {!loading && data && (
              <DataGrid
                rows={data}
                columns={columns}
                components={{ Toolbar: GridToolbar }}
                sx={{
                  "& .MuiDataGrid-cell": {
                    fontSize: "16px", // Adjust the font size as needed
                    color: "black",
                  },
                  "& .MuiDataGrid-columnHeader": {
                    fontSize: "16px",
                    color: "black",
                    fontWeight: "bold", // Adjust the font size as needed
                  },
                }}
                checkboxSelection={true}
              />
            )}
          </Box>
        </Box>
      </main>
    </div>
  );
};

export default Admin;
