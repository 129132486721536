import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  Scanner: [],
  addScanner: [],
  updateScanner: [],
  deleteScanner: [],
};

const ScannerSlice = createSlice({
  name: "Scanner",
  initialState,
  reducers: {
    ScannerRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    ScannerSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.Scanner = action.payload;
    },

    ScannerFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    addScannerRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    addScannerSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.addScanner = action.payload;
    },

    addScannerFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateScannerRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    updateScannerSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.updateScanner = action.payload;
    },

    updateScannerFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteScannerRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    deleteScannerSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.deleteScanner = action.payload;
    },

    deleteScannerFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const ScannerAction = ScannerSlice.actions;
export default ScannerSlice;
