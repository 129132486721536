import axios from "axios";
import { PrinterAction } from "./PrinterReducers";

export const getPrinters = () => async (dispatch) => {
  try {
    dispatch(PrinterAction.printerRequest());

    const res = await axios.get(process.env.REACT_APP_API + "/api/printers");

    dispatch(PrinterAction.printerSuccess(res));
  } catch (error) {
    dispatch(PrinterAction.printerFail(error));
  }
};

export const addPrinter =
  (name, serial_number) =>
  async (dispatch) => {
    dispatch(PrinterAction.addPrinterRequest());
    const token = localStorage.getItem("jwtToken");

    const formData = new FormData();
    formData.append("name", name);
    formData.append("serial_number", serial_number);

    try {
      await axios.post(
        process.env.REACT_APP_API + "/api/printers",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      dispatch(PrinterAction.addPrinterSuccess());
      dispatch(getPrinters());
    } catch (e) {
      dispatch(PrinterAction.addPrinterFail(e.response.data));
    }
  };

export const updatePrinter =
  (id, name, serial_number) =>
  async (dispatch) => {
    dispatch(PrinterAction.updatePrinterRequest());
    const token = localStorage.getItem("jwtToken");

  

    try {
      await axios.put(
        process.env.REACT_APP_API + "/api/printers/" + id,
        { name, serial_number},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            // "Content-Type": "multipart/form-data",
          },
        }
      );
      dispatch(PrinterAction.updatePrinterSuccess());
      dispatch(getPrinters());
    } catch (e) {
      dispatch(PrinterAction.updatePrinterFail(e.response.data));
    }
  };

export const deletePrinter = (id) => async (dispatch) => {
  dispatch(PrinterAction.deletePrinterRequest());
  const token = localStorage.getItem("jwtToken");
  try {
    await axios.delete(process.env.REACT_APP_API + "/api/printers/" + id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(PrinterAction.deletePrinterSuccess());
    dispatch(getPrinters());
  } catch (e) {
    dispatch(PrinterAction.deletePrinterFail(e.response.data));
  }
};
