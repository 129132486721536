import React from "react";
import { useForm } from "react-hook-form";

import Teeth from "./teeth";
import "./pdf.css";
import Anterior from "./anterior";
import Midline from "./midline";
import html2pdf from "html2pdf.js";
import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import { useNavigate } from "react-router-dom";
import { getpatientbyid} from "../../Redux/Patient/PatientActions";
import { getclinicbyid } from "../../Redux/Clinic/ClinicActions";


function PPDDFF() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const clinincid =localStorage.getItem("id");
  const { patientid } = useSelector((state) => state.Patient);

  const { Clinicid } = useSelector((state) => state.Clinic);
const [patient, setPatient] = useState([]);
  const [clinic, setClinic] = useState([]);
  useEffect(() => {
    if (patientid) {
      setPatient(patientid?.data?.patient);
    }
  }, [patientid]);
  useEffect(() => {
    if (Clinicid) {
      setClinic(Clinicid?.data?.clinic);
    }
  }, [Clinicid]);
  

  useEffect(() => {
    dispatch(getpatientbyid(id));
    dispatch(getclinicbyid(clinincid));

  }, []);

  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    const element = document.documentElement;
    const widthInPixels = document.documentElement.scrollWidth;
    const heightInPixels = document.documentElement.scrollHeight;
    const widthInInches = widthInPixels / 96;
    const heightInInches = heightInPixels / 96;

    const opt = {
      margin: 0,
      filename: "treatment.pdf",
      image: { type: "jpeg" },
      html2canvas: { scale: 1 },
      jsPDF: {
        unit: "in",
        format: [widthInInches, heightInInches],
        orientation: "portrait",
      },
    };

    // Replace input elements with divs
    const inputs = Array.from(document.querySelectorAll("input[type=text]"));
    inputs.forEach((input) => {
      const div = document.createElement("div");
      div.textContent = input.value;
      div.style.cssText = window.getComputedStyle(input).cssText;
      input.parentNode.replaceChild(div, input);
    });

    // Create a worker
    const worker = html2pdf().from(element).set(opt).toPdf().get("pdf");

    worker.output("blob").then((pdfOutput) => {
      const formData = new FormData();
      formData.append("treatment_pdf", pdfOutput);
      formData.append("patient_id", id);

      axios
        .post(process.env.REACT_APP_API + "/api/pdf", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          navigate(`/Treatment/${id}?name=${patient?.first_name + " " + patient?.last_name}`);
        })
        .catch((error) => {
          console.error(error);
        });
    });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
      }}
    >
      <div className="top">
      <div className="logo">
          <img
            src="./../logo.png"
            alt="Fixalign"
          />
        </div>
      <div className="patient-info">
        {/* name */}
        <div>
          <div>
            <label htmlFor="name">Patient Name:</label>
             &nbsp; {patient?.first_name}  {patient?.last_name}
          </div>
          <div>
            <label htmlFor="mobile">Patient Mobile:</label>
            &nbsp; {patient?.user?.phone_number}
          </div>
          <div>
            <label htmlFor="id">Patient Id:</label>
            &nbsp; {id}
          </div>
        </div>
        <div>
          <div>
            <label htmlFor="ClinicName">Clinic Name:</label>
            &nbsp; {clinic?.name}
          </div>
          <div>
            <label htmlFor="doctorName">Doctor Name:</label>
            &nbsp; {clinic?.doctor_name}
          </div>
          <div>
            <label htmlFor="doctorMobile">Doctor Email:</label>
            &nbsp; {clinic?.doctor_phone_number}
          </div>
        </div>
      </div>
      
      </div>
      <div
        className="big"
        style={{
          color: "black",
        }}
      >
        <div className="big-left">
          <div className="treated-arches">
            <h3>TREATED ARCHES</h3>
            <span>
              <input
                type="radio"
                id="option1"
                name="option"
                value="Upper Arch Only"
                {...register("option")}
              />
              <label htmlFor="option1">Upper Only</label>
              <input
                type="radio"
                id="option2"
                name="option"
                value="Lower Arch Only"
                {...register("option")}
              />
              <label htmlFor="option2">Lower Only</label>
              <input
                type="radio"
                id="option3"
                name="option"
                value="Both Arches"
                {...register("option")}
              />
              <label htmlFor="option3">Both</label>
            </span>
          </div>
          <div className="tooth-move-restriction">
            <h3>TOOTH MOVEMENT RESTRICTION</h3>
            <p>Do not move these teeth:</p>
            <p>(Note: bridges, ankylosed teeth or implants not to be moved)</p>
            <div className="mouth">
              <div>R</div>
              <span className="teeth-32">
                {/* 32 checkboxes into 4 quartes like teeth style */}
                <div className="half">
                  <div className="quarter">
                    {[
                      "1.8",
                      "1.7",
                      "1.6",
                      "1.5",
                      "1.4",
                      "1.3",
                      "1.2",
                      "1.1",
                    ].map((number) => (
                      <Teeth key={number} number={number} position={"upper"} />
                    ))}
                  </div>
                  <div className="quarter">
                    {["2.8", "2.7", "2.6", "2.5", "2.4", "2.3", "2.2", "2.1"]
                      .reverse()
                      .map((number) => (
                        <Teeth
                          key={number}
                          number={number}
                          position={"upper"}
                        />
                      ))}
                  </div>
                </div>
                <div className="half">
                  <div className="quarter">
                    {[
                      "4.8",
                      "4.7",
                      "4.6",
                      "4.5",
                      "4.4",
                      "4.3",
                      "4.2",
                      "4.1",
                    ].map((number) => (
                      <Teeth key={number} number={number} position={"lower"} />
                    ))}
                  </div>
                  <div className="quarter">
                    {["3.8", "3.7", "3.6", "3.5", "3.4", "3.3", "3.2", "3.1"]
                      .reverse()
                      .map((number) => (
                        <Teeth
                          key={number}
                          number={number}
                          position={"lower"}
                        />
                      ))}
                  </div>
                </div>
              </span>
              <div>L</div>
            </div>
          </div>
          <div className="tooth-move-restriction">
            <h3>DO NOT PLACE ATTACHMENTS ON THESE TEETH</h3>
            <p>(Note: crowns, labial or buccal restorations)</p>
            <div className="mouth">
              <div>R</div>
              <span className="teeth-32">
                {/* 32 checkboxes into 4 quartes like teeth style */}
                <div className="half">
                  <div className="quarter">
                    {[
                      "1.8",
                      "1.7",
                      "1.6",
                      "1.5",
                      "1.4",
                      "1.3",
                      "1.2",
                      "1.1",
                    ].map((number) => (
                      <Teeth key={number} number={number} position={"upper"} />
                    ))}
                  </div>
                  <div className="quarter">
                    {["2.8", "2.7", "2.6", "2.5", "2.4", "2.3", "2.2", "2.1"]
                      .reverse()
                      .map((number) => (
                        <Teeth
                          key={number}
                          number={number}
                          position={"upper"}
                        />
                      ))}
                  </div>
                </div>
                <div className="half">
                  <div className="quarter">
                    {[
                      "4.8",
                      "4.7",
                      "4.6",
                      "4.5",
                      "4.4",
                      "4.3",
                      "4.2",
                      "4.1",
                    ].map((number) => (
                      <Teeth key={number} number={number} position={"lower"} />
                    ))}
                  </div>
                  <div className="quarter">
                    {["3.8", "3.7", "3.6", "3.5", "3.4", "3.3", "3.2", "3.1"]
                      .reverse()
                      .map((number) => (
                        <Teeth
                          key={number}
                          number={number}
                          position={"lower"}
                        />
                      ))}
                  </div>
                </div>
              </span>
              <div>L</div>
            </div>
          </div>
          <div className="check-then-check">
            <Anterior />
          </div>
        </div>
        <div className="big-right">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "4rem",
              padding: "5px",
              borderRadius: "5px",
              marginTop: "10px",
            }}
          >
            <div className="overjet">
              <h3>OVERJET UPPER</h3>
              <span
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "0.1rem",
                }}
              >
                <div>
                  <input
                    type="radio"
                    id="overjet1"
                    name="overjet"
                    value="Normal"
                    {...register("overjet")}
                  />
                  <label htmlFor="overjet1">
                    Show resulting after alignment
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="overjet2"
                    name="overjet"
                    value="Increased"
                    {...register("overjet")}
                  />
                  <label htmlFor="overjet2">
                    Maintain initial (may require IPR)
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="overjet3"
                    name="overjet"
                    value="Decreased"
                    {...register("overjet")}
                  />
                  <label htmlFor="overjet3">Improve resulting</label>
                </div>
              </span>
            </div>
            <div className="overbite">
              <h3>OVERBITE</h3>
              <span
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "0.1rem",
                }}
              >
                <div>
                  <input
                    type="radio"
                    id="overbite1"
                    name="overbite"
                    value="Normal"
                    {...register("overbite")}
                  />
                  <label htmlFor="overbite1">
                    Show resulting after alignment
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="overbite2"
                    name="overbite"
                    value="Increased"
                    {...register("overbite")}
                  />
                  <label htmlFor="overbite2">
                    Maintain initial (may require IPR)
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="overbite3"
                    name="overbite"
                    value="Decreased"
                    {...register("overbite")}
                  />
                  <label htmlFor="overbite3">Improve resulting</label>
                </div>
              </span>
            </div>
          </div>
          <div
            className="bite-ramps"
            style={{
              padding: "5px",
              borderRadius: "5px",
              marginTop: "20px",
            }}
          >
            <h3>BITE RAMPS</h3>
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "0.1rem",
              }}
            >
              <div>
                <input
                  type="radio"
                  id="bite-ramps1"
                  name="bite-ramps"
                  value="None"
                  {...register("bite-ramps")}
                />
                <label htmlFor="bite-ramps1">None</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="bite-ramps2"
                  name="bite-ramps"
                  value="Place Bite Ramps on lingual of these upper teeth"
                  {...register("bite-ramps")}
                />
                <label htmlFor="bite-ramps2">
                  Place Bite Ramps on lingual of these upper teeth
                </label>
              </div>
              <span>&nbsp;&nbsp;&nbsp;Incisors</span>
              <div>
                <div>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <input
                    type="radio"
                    id="bite-ramps3"
                    name="bite-ramps"
                    value="Canines"
                    {...register("bite-ramps")}
                  />
                  <label htmlFor="bite-ramps3">
                    Central incisors &nbsp; &nbsp; &nbsp; &nbsp;
                  </label>
                  <input
                    type="radio"
                    id="bite-ramps4"
                    name="bite-ramps"
                    value="Lateral incisors"
                    {...register("bite-ramps")}
                  />
                  <label htmlFor="bite-ramps4">Lateral incisors</label>
                </div>
                <div></div>
                <div>
                  <label>
                    Note: Placement of Bite Ramps will take the place of the
                    upper anterior intrusion features (pressure areas) if
                    applicable.
                  </label>
                </div>
              </div>
              <div>
                <input
                  type="radio"
                  id="bite-ramps6"
                  name="bite-ramps"
                  value="Canines"
                  {...register("bite-ramps")}
                />
                <label htmlFor="bite-ramps6">Canines</label>
              </div>
            </span>
          </div>
          <div className="check-then-check">
            <Midline />
          </div>

          <div
            className="spacing-res"
            style={{
              padding: "5px",
              borderRadius: "5px",
              marginTop: "20px",
            }}
          >
            <h3>SPACING RESOLUTION</h3>
            <div
              style={{
                display: "flex",
                gap: "2rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <span>Upper</span>
                <div>
                  <input
                    type="radio"
                    id="spacing-res1"
                    name="spacing-res-upper"
                    value="Close spaces"
                    {...register("spacing-res-upper")}
                  />
                  <label htmlFor="spacing-res1">Close spaces</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="spacing-res2"
                    name="spacing-res-upper"
                    value="Leave spaces"
                    {...register("spacing-res-upper")}
                  />
                  <label htmlFor="spacing-res2">Leave spaces</label>
                  <div>
                    <input
                      type="text"
                      id="spacing-location-upper"
                      name="spacing-location-upper"
                      placeholder="Specify where"
                      {...register("spacing-location-upper")}
                      style={{
                        width: "110%",
                        border: "none",
                        padding: "5px",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <span>Lower</span>
                <div>
                  <input
                    type="radio"
                    id="spacing-res3"
                    name="spacing-res-lower"
                    value="Close spaces"
                    {...register("spacing-res-lower")}
                  />
                  <label htmlFor="spacing-res3">Close spaces</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="spacing-res4"
                    name="spacing-res-lower"
                    value="Leave spaces"
                    {...register("spacing-res-lower")}
                  />
                  <label htmlFor="spacing-res4">Leave spaces</label>
                  <div>
                    <input
                      type="text"
                      id="spacing-location-lower"
                      name="spacing-location-lower"
                      placeholder="Specify where"
                      {...register("spacing-location-lower")}
                      style={{
                        width: "110%",
                        border: "none",
                        padding: "5px",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="spacing-res"
            style={{
              padding: "5px",
              borderRadius: "5px",
              marginTop: "20px",
            }}
          >
            <h3>CASE CHECK LIST</h3>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
              }}
            >
              <div>
                <input
                  type="checkbox"
                  id="case-check1"
                  name="case-check1"
                  {...register("case-check1")}
                />
                <label htmlFor="case-check1">OPG</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  id="case-check2"
                  name="case-check2"
                  {...register("case-check2")}
                />
                <label htmlFor="case-check2">Lateral Ceph</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  id="case-check3"
                  name="case-check3"
                  {...register("case-check3")}
                />
                <label htmlFor="case-check3">8 Clinical Photos</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  id="case-check4"
                  name="case-check4"
                  {...register("case-check4")}
                />
                <label htmlFor="case-check4">Bite Registration</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  id="case-check5"
                  name="case-check5"
                  {...register("case-check5")}
                />
                <label htmlFor="case-check5">Upper PVS Impressions</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  id="case-check6"
                  name="case-check6"
                  {...register("case-check6")}
                />
                <label htmlFor="case-check6">Lower PVS Impressions</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom">
      <div className="logo">
        </div>
      <input type="submit" style={
        {
          padding: "10px",
          backgroundColor: "#ff00ff",
          color: "white",
          borderRadius: "5px",
          marginTop: "20px",
          cursor: "pointer",
        }
      } />
      </div>
    </form>
  );
}

export default PPDDFF;
