import axios from "axios";
import { DesignerAction } from "./DesignerReducers";

export const getDesigners = () => async (dispatch) => {
  try {
    dispatch(DesignerAction.designerRequest());

    const res = await axios.get(process.env.REACT_APP_API + "/api/designers");

    dispatch(DesignerAction.designerSuccess(res));
  } catch (error) {
    dispatch(DesignerAction.designerFail(error));
  }
};

export const addDesigner =
  (
    first_name,
    last_name,
    email,
    phone_number
  ) =>
  async (dispatch) => {
    dispatch(DesignerAction.addDesignerRequest());
    const token = localStorage.getItem("jwtToken");

    const formData = new FormData();
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("email", email);
    formData.append("phone_number", phone_number);

    try {
      await axios.post(
        process.env.REACT_APP_API + "/api/designers",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      dispatch(DesignerAction.addDesignerSuccess());
      dispatch(getDesigners());
    } catch (e) {
      dispatch(DesignerAction.addDesignerFail(e.response.data));
    }
  };

export const updateDesigner =
  (
    id,
    first_name,
    last_name,
    email,
    phone_number
  ) =>
  async (dispatch) => {
    dispatch(DesignerAction.updateDesignerRequest());
    const token = localStorage.getItem("jwtToken");

 
    try {
      await axios.put(
        process.env.REACT_APP_API + "/api/designers/" + id,{first_name,last_name,email,phone_number},


        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(DesignerAction.updateDesignerSuccess());
      dispatch(getDesigners());
    } catch (e) {
      dispatch(DesignerAction.updateDesignerFail(e.response.data));
    }
  };

  
  export const deleteDesigner = (id) => async (dispatch) => {
    dispatch(DesignerAction.deleteDesignerRequest());
    const token = localStorage.getItem("jwtToken");
    try {
      await axios.delete(process.env.REACT_APP_API + "/api/designers/" + id, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(DesignerAction.deleteDesignerSuccess());
      dispatch(getDesigners());
    } catch (e) {
      dispatch(DesignerAction.deleteDesignerFail(e.response.data));
    }
  };
  