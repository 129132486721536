import axios from "axios";
import { ContactAction } from "./ContactReducers";

export const getcontactus = () => async (dispatch) => {
  try {
    dispatch(ContactAction.ContactRequest());

    const res = await axios.get(process.env.REACT_APP_API + "/api/contact-us");

    dispatch(ContactAction.ContactSuccess(res));
  } catch (error) {
    dispatch(ContactAction.ContactFail(error));
  }
};
