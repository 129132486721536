import React, { useState } from "react";

const Midline = () => {
  const [midline1, setMidline1] = useState(false);
  const [midline2, setMidline2] = useState(false);

  return (
    <div
      style={{
        padding: "15px",
        borderRadius: "5px",
        marginTop: "20px",
        color: "black",
      }}
    >
      <h3 style={{ fontSize: "1.2rem", marginBottom: "0.5rem" }}>
        MIDLINE CHANGE: RECOMMENDED LIMIT &lt; 2MM
      </h3>
      {["Maintain Upper/MOVE", "Maintain Lower/MOVE"].map((item, index) => {
        const midline = [midline1, midline2][index];
        const setMidline = [setMidline1, setMidline2][index];

        return (
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              fontSize: "0.9rem",
              padding: "0.1rem",
              marginBottom: "0.5rem",
            }}
          >
            <div style={{ marginRight: "0.5rem" }}>
              <input
                type="checkbox"
                id={`maintains-${index}`}
                name={`maintains-${index}`}
                checked={midline}
                onChange={() => setMidline(!midline)}
                style={{
                  width: "1.2rem",
                  height: "1.2rem",
                  marginRight: "0.5rem",
                  cursor: "pointer",
                }}
              />
              {midline && <span style={{ fontSize: "1.2rem" }}>&times;</span>}
              <label htmlFor={`maintains-${index}`}>{item}</label>
            </div>
            <div>
              <input
                type="checkbox"
                id={`right-${index}`}
                name={`right-${index}`}
                disabled={!midline}
              />
              <label htmlFor={`right-${index}`}>Right</label>
            </div>
            <div>
              <input
                type="checkbox"
                id={`left-${index}`}
                name={`left-${index}`}
                disabled={!midline}
              />
              <label htmlFor={`left-${index}`}>Left</label>
            </div>
            <div>
              <input
                type="checkbox"
                id={`1-2mm-${index}`}
                name={`1-2mm-${index}`}
                disabled={!midline}
              />
              <label htmlFor={`1-2mm-${index}`}>1-2mm</label>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Midline;
