import React, { useState } from "react";

const Teeth = ({ number, position }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleChange = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <label
      style={{
        display: "flex",
        flexDirection: position === "upper" ? "column-reverse" : "column",
        alignItems: "center",
        fontSize: "0.7rem",
        padding: "0.1rem",
        color: "black",
      }}
    >
      <input
        type="checkbox"
        checked={isChecked}
        onChange={handleChange}
        style={{
          display: "none",
        }}
      />
      <span
        className={`checkmark ${isChecked ? "checked" : ""}`}
        style={{
          display: "inline-block",
          width: "1.2rem",
          height: "1.2rem",
          margin: "0",
          border: "2px solid",
          cursor: "pointer",
          position: "relative",
        }}
      >
        {isChecked && (
          <span
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -58%)",
              color: "black",
              fontSize: "1.5rem",
              fontWeight: "bold",
            }}
          >
            &times;
          </span>
        )}
      </span>
      <div>{number}</div>
    </label>
  );
};

export default Teeth;
