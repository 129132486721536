import axios from "axios";
import { AppointmentAction } from "./AppointmentReducers";

export const getAppointment = () => async (dispatch) => {
  const token = localStorage.getItem("jwtToken");

  try {
    dispatch(AppointmentAction.AppointmentRequest());

    const res = await axios.get(
      process.env.REACT_APP_API + "/api/appointments",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch(AppointmentAction.AppointmentSuccess(res));
  } catch (error) {
    dispatch(AppointmentAction.AppointmentFail(error));
  }
};
export const getAppointmentbyclinicid = (id) => async (dispatch) => {
  const token = localStorage.getItem("jwtToken");

  try {
    dispatch(AppointmentAction.AppointmentRequest());

    const res = await axios.get(
      process.env.REACT_APP_API + "/api/appointments-clinic/" + id,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch(AppointmentAction.appointmentbiclinicidSuccess(res));
  } catch (error) {
    dispatch(AppointmentAction.AppointmentFail(error));
  }
};

export const assignappointment =
  (id, clinic_id, appointment_date) => async (dispatch) => {
    dispatch(AppointmentAction.assignappointmentRequest());
    const token = localStorage.getItem("jwtToken");
    try {
      await axios.put(
        process.env.REACT_APP_API + "/api/appointments/" + id,
        {
          clinic_id,
          appointment_date,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(AppointmentAction.assignappointmentSuccess());
      dispatch(getAppointment());
    } catch (e) {
      dispatch(AppointmentAction.assignappointmentFail(e.response.data));
    }
  };
export const approveappointment = (id) => async (dispatch) => {
  const token = localStorage.getItem("jwtToken");

  try {
    dispatch(AppointmentAction.approveappointmentRequest());

    const res = await axios.get(
      process.env.REACT_APP_API + "/api/appointments/" + id + "/approve",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch(AppointmentAction.approveappointmentSuccess(res));
    dispatch(getAppointment());
  } catch (error) {
    dispatch(AppointmentAction.approveappointmentFail(error));
  }
};
