import axios from "axios";
import { TreatmentAction } from "./TreatmentReducers";

export const getTreatment = () => async (dispatch) => {
  try {
    dispatch(TreatmentAction.TreatmentRequest());

    const res = await axios.get(process.env.REACT_APP_API + "/api/Treatments");

    dispatch(TreatmentAction.TreatmentSuccess(res));
  } catch (error) {
    dispatch(TreatmentAction.TreatmentFail(error));
  }
};
export const AddTreatment =
  (
    title,
    description1,
    description2,
    description3,
    price,
    number_of_treatments
  ) =>
  async (dispatch) => {
    dispatch(TreatmentAction.addTreatmentRequest());
    const token = localStorage.getItem("jwtToken");
    try {
      await axios.post(
        process.env.REACT_APP_API + "/api/Treatments",
        {
          title,
          description1,
          description2,
          description3,
          price,
          number_of_treatments,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(TreatmentAction.addTreatmentSuccess());
      dispatch(getTreatment());
    } catch (e) {
      dispatch(TreatmentAction.addTreatmentFail(e.response.data));
    }
  };

export const deleteTreatment = (id, filename) => async (dispatch) => {
  dispatch(TreatmentAction.deletescanRequest());
  const token = localStorage.getItem("jwtToken");
  try {
    //id?
    await axios.delete(
      process.env.REACT_APP_API + "/api/patients/scan/" + id + "/" + filename,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(TreatmentAction.deletescanSuccess());
    dispatch(getscan());
  } catch (e) {
    dispatch(TreatmentAction.deletescanFail(e.response.data));
  }
};

export const getscan = (id) => async (dispatch) => {
  const token = localStorage.getItem("jwtToken");
  try {
    dispatch(TreatmentAction.scanRequest());

    const res = await axios.get(
      process.env.REACT_APP_API + "/api/patients/scan/" + id,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(TreatmentAction.scanSuccess(res));
  } catch (error) {
    dispatch(TreatmentAction.scanFail(error));
  }
};
export const Addscan = (id, scan, scanner_id) => async (dispatch) => {
  const formdata = new FormData();
  for (let i = 0; i < scan.length; i++) {
    formdata.append("scan[]", scan[i]);
  }
  formdata.append("scanner_id", scanner_id);
  dispatch(TreatmentAction.addscanRequest());
  const token = localStorage.getItem("jwtToken");
  try {
    await axios.post(
      process.env.REACT_APP_API + "/api/patients/scan/" + id,
      formdata,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(TreatmentAction.addscanSuccess());
    dispatch(getscan(id));
  } catch (e) {
    dispatch(TreatmentAction.addscanFail(e.response.data));
  }
};

export const deletescan = (id, filename) => async (dispatch) => {
  dispatch(TreatmentAction.deletescanRequest());
  const token = localStorage.getItem("jwtToken");
  try {
    //id?
    await axios.delete(
      process.env.REACT_APP_API + "/api/patients/scan/" + id + "/" + filename,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(TreatmentAction.deletescanSuccess());
    dispatch(getscan(id));
  } catch (e) {
    dispatch(TreatmentAction.deletescanFail(e.response.data));
  }
};
