import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  Clinic: [],
  addclinic: [],
  updateClinic: [],
  deleteClinic: [],
  loadingadd: null,
  Clinicid : [],
};

const ClinicSlice = createSlice({
  name: "Clinic",
  initialState,
  reducers: {
    ClinicRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.Clinic = [];

    },
    ClinicSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.Clinic = action.payload;
    },

    ClinicFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.Clinic = [];
    },


    addClinicRequest: (state, action) => {
      state.loadingadd = true;
      state.error = null;
      state.addclinic = [];
    },
    addClinicSuccess: (state, action) => {
      state.loadingadd = false;
      state.error = null;
      state.addclinic = action.payload;
    },

    addClinicFail: (state, action) => {
      state.loadingadd = false;
      state.error = action.payload;
      state.addclinic = [];
    },



    updateClinicRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    updateClinicSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.updateClinic = action.payload;
    },

    updateClinicFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteClinicRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    deleteClinicSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.deleteClinic = action.payload;
    },

    deleteClinicFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

     ClinicidRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    ClinicidSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.Clinicid = action.payload;
    },

    ClinicidFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const ClinicAction = ClinicSlice.actions;
export default ClinicSlice;
