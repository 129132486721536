import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  login: [],
  company: [],
  loadingForgetPassword: false,
  errorForgetPassword: null,
  ForgetPassword: [],
  changepassword: [],
  getstatistic: [],
  loadinggetstatistic: false,
  errorgetstatistic: null,
};

const AuthSlice = createSlice({
  name: "AuthReducers",
  initialState,
  reducers: {
    LoginRequest: (state) => {
      state.loading = true;
      state.error = null;
      state.login = [];
    },
    LoginSuccess: (state, action) => {
      state.loading = false;
      state.login = action.payload;
      state.error = null;
    },
    LoginFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.login = [];
    },

    ForgetPasswordRequest: (state) => {
      state.loadingForgetPassword = true;
    },
    ForgetPasswordSuccess: (state, action) => {
      state.loadingForgetPassword = false;
      state.ForgetPassword = action.payload;
    },
    ForgetPasswordFail: (state, action) => {
      state.loadingForgetPassword = false;
      state.errorForgetPassword = action.payload;
    },
    

    getstatisticRequest: (state) => {
      state.loadinggetstatistic = true;
    },
    getstatisticSuccess: (state, action) => {
      state.loadinggetstatistic = false;
      state.getstatistic = action.payload;
    },
    getstatisticFail: (state, action) => {
      state.loadinggetstatistic = false;
      state.errorgetstatistic = action.payload;
    },

  },
});

export const AuthActions = AuthSlice.actions;
export default AuthSlice;
