import axios from "axios";
import { LabAction } from "./LabReducers";

export const getLab = () => async (dispatch) => {
  const token = localStorage.getItem("jwtToken");

  try {
    dispatch(LabAction.LabRequest());

    const res = await axios.get(process.env.REACT_APP_API + "/api/labs", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch(LabAction.LabSuccess(res));
  } catch (error) {
    dispatch(LabAction.LabFail(error));
  }
};

export const AddLab =
  (email, phone_number, district_id, name, address) => async (dispatch) => {
    dispatch(LabAction.addLabRequest());
    const token = localStorage.getItem("jwtToken");
    try {
      await axios.post(
        process.env.REACT_APP_API + "/api/labs",
        {
          email,
          phone_number,
          district_id,
          name,
          address,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(LabAction.addLabSuccess());
      dispatch(getLab());
    } catch (e) {
      dispatch(LabAction.addLabFail(e.response.data));
    }
  };

export const updateLab =
  (id, email, phone_number, district_id, name, address) => async (dispatch) => {
    dispatch(LabAction.updateLabRequest());
    const token = localStorage.getItem("jwtToken");
    try {
      await axios.put(
        process.env.REACT_APP_API + "/api/labs/" + id,
        {

          name,
          address,
          user: {
            email,
            phone_number,
            district_id,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(LabAction.updateLabSuccess());
      dispatch(getLab());
    } catch (e) {
      dispatch(LabAction.updateLabFail(e.response.data));
    }
  };
export const deleteLab = (id) => async (dispatch) => {
  dispatch(LabAction.deleteLabRequest());
  const token = localStorage.getItem("jwtToken");
  try {
    //id?
    await axios.delete(process.env.REACT_APP_API + "/api/labs/" + id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(LabAction.deleteLabSuccess());
    dispatch(getLab());
  } catch (e) {
    dispatch(LabAction.deleteLabFail(e.response.data));
  }
};
