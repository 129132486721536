import axios from "axios";
import { AuthActions } from "./AuthReducers";
export const user_Login = (email, password) => async (dispatch) => {
  try {
    dispatch(AuthActions.LoginRequest());

    const res = await axios.post(process.env.REACT_APP_API + "/api/login", {
      email: email,
      password: password,
    });

    if (res?.data?.authorization?.token) {
      const token = res?.data?.authorization?.token;
      const useremail = res?.data?.user?.email;

      const role = res?.data?.user?.user_type_id;
      localStorage.setItem("jwtToken", token);
      localStorage.setItem("email", useremail);

      if (role === 1) {
        localStorage.setItem("role", "admin");
        localStorage.setItem("id", res?.data?.user?.id);
        window.location.href = "/";
      } else if (role === 3) {
        localStorage.setItem("role", "clinic");
        localStorage.setItem("id", res?.data?.authorization?.clinic?.id);
        window.location.href = "/";
      } else if (role === 4) {
        localStorage.setItem("role", "lab");
        window.location.href = "/patient";
      }
      else if (role === 6) {
        localStorage.setItem("role", "superadmin");
        window.location.href = "/";
      }

     
      dispatch(AuthActions.LoginSuccess(res.data));
    } else {
      dispatch(AuthActions.LoginFail("You are not admin !"));
    }
  } catch (error) {
    dispatch(AuthActions.LoginFail(error));
  }
};

export const logout = () => async (dispatch) => {
  try {
    localStorage.clear();

    window.location.href = "/login";
    dispatch(AuthActions.logout());
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("email");
  } catch (error) {}
};

export const forgetPassword = (email) => async (dispatch) => {
  try {
    dispatch(AuthActions.ForgetPasswordRequest());
    const res = await axios.put(
      process.env.REACT_APP_API + "/user/forgetPassword",
      { email: email }
    );
    dispatch(AuthActions.ForgetPasswordSuccess(res.data.data));
  } catch (error) {
    dispatch(AuthActions.ForgetPasswordFail(error));
  }
};
export const getstatistics = () => async (dispatch) => {
  try {
    dispatch(AuthActions.getstatisticRequest());

    const res = localStorage.getItem("role") == "clinic"? await axios.get(process.env.REACT_APP_API + "/api/clinics/stats/"+ localStorage.getItem("id")) : await axios.get(process.env.REACT_APP_API + "/api/stats");

    dispatch(AuthActions.getstatisticSuccess(res));
  } catch (error) {
    dispatch(AuthActions.getstatisticFail(error));
  }
};