import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  Appointment: [],
  assignappointment: [],
  approveappointment: [],
  appointmentbiclinicid: [],
};

const AppointmentSlice = createSlice({
  name: "Appointment",
  initialState,
  reducers: {
    AppointmentRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    AppointmentSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.Appointment = action.payload;
    },

    AppointmentFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    assignappointmentRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    assignappointmentSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.assignappointment = action.payload;
    },

    assignappointmentFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    approveappointmentRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    approveappointmentSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.approveappointment = action.payload;
    },

    approveappointmentFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    appointmentbiclinicidSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.appointmentbiclinicid = action.payload;
    },
  },
});

export const AppointmentAction = AppointmentSlice.actions;
export default AppointmentSlice;
