import axios from "axios";
import { PlanAction } from "./PlanReducers";

export const getplan = () => async (dispatch) => {
  try {
    dispatch(PlanAction.planRequest());

    const res = await axios.get(process.env.REACT_APP_API + "/api/plans");

    dispatch(PlanAction.planSuccess(res));
  } catch (error) {
    dispatch(PlanAction.planFail(error));
  }
};
export const Addplan =
  (
    title,
    description1,
    description2,
    description3,
    price,
    number_of_treatments
  ) =>
  async (dispatch) => {
    dispatch(PlanAction.addplanRequest());
    const token = localStorage.getItem("jwtToken");
    try {
      await axios.post(
        process.env.REACT_APP_API + "/api/plans",
        {
          title,
          description1,
          description2,
          description3,
          price,
          number_of_treatments,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(PlanAction.addplanSuccess());
      dispatch(getplan());
    } catch (e) {
      dispatch(PlanAction.addplanFail(e.response.data));
    }
  };

export const updateplan =
  (
    id,
    title,
    description1,
    description2,
    description3,
    price,
    number_of_treatments
  ) =>
  async (dispatch) => {
    dispatch(PlanAction.updateplanRequest());
    const token = localStorage.getItem("jwtToken");
    try {
      await axios.put(
        process.env.REACT_APP_API + "/api/plans/" + id,
        {
          title,
          description1,
          description2,
          description3,
          price,
          number_of_treatments,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(PlanAction.updateplanSuccess());
      dispatch(getplan());
    } catch (e) {
      dispatch(PlanAction.updateplanFail(e.response.data));
    }
  };
export const deleteplan = (id) => async (dispatch) => {
  dispatch(PlanAction.deleteplanRequest());
  const token = localStorage.getItem("jwtToken");
  try {
    //id?
    await axios.delete(process.env.REACT_APP_API + "/api/plans/" + id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(PlanAction.deleteplanSuccess());
    dispatch(getplan());
  } catch (e) {
    dispatch(PlanAction.deleteplanFail(e.response.data));
  }
};
