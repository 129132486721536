import React from "react";
import "./../scenes/Style.css";
import loading from "./30.gif";

function Loading() {
  const divStyles = {
    transform:
      "translate3d(-100%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
    transformStyle: "preserve-3d",
  };

  return (
    <div className="Loading">
      <img src="https://assets-global.website-files.com/64b569c2bc33b85e3416e787/64b569c2bc33b85e3416e7d7_icon-logo-dentist-template.svg" />
      <img src={loading} alt="loading" />
      <div style={divStyles}></div>
    </div>
  );
}

export default Loading;
