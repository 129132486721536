import BookOnlineIcon from "@mui/icons-material/BookOnline";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { Menu, MenuItem, ProSidebar } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Link } from "react-router-dom";
import { tokens } from "../../theme";
import "./../../scenes/Style.css";
// import ChecklistIcon from "@mui/icons-material/Checklist";
import AirlineSeatReclineExtraIcon from "@mui/icons-material/AirlineSeatReclineExtra";
import DomainIcon from "@mui/icons-material/Domain";
import EditLocationIcon from "@mui/icons-material/EditLocation";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import QuizIcon from "@mui/icons-material/Quiz";
import ReorderIcon from "@mui/icons-material/Reorder";
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  return (
    <Box
      className="custom-box"
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
          zIndex: "0",
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
          zIndex: "0",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
              <svg width="100" height="100" viewBox="0 0 851 260" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_230_897)">
<path d="M146.2 201.2C138.9 201.2 133 195.3 133 188V87.8C133 80.5 138.9 74.6 146.2 74.6C153.5 74.6 159.4 80.5 159.4 87.8V188C159.4 195.3 153.5 201.2 146.2 201.2Z" fill="#FF00FF"/>
<path d="M550.7 201.2C543.4 201.2 537.5 195.3 537.5 188V87.8C537.5 80.5 543.4 74.6 550.7 74.6C558 74.6 563.9 80.5 563.9 87.8V188C563.9 195.3 558 201.2 550.7 201.2Z" fill="#FF00FF"/>
<path d="M494.6 201.2C487.3 201.2 481.4 195.3 481.4 188V50.6C481.4 43.3 487.3 37.3 494.6 37.3C501.9 37.3 507.8 43.3 507.8 50.6V188C507.8 195.3 501.9 201.2 494.6 201.2Z" fill="#FF00FF"/>
<path d="M159.4 13.5C159.4 6.2 153.5 0.300003 146.2 0.300003H26.6001H19.4001C8.7001 0.300003 0.100098 8.9 0.100098 19.5V188C0.100098 195.3 6.1001 201.2 13.4001 201.2C20.7001 201.2 26.6001 195.3 26.6001 188V152.2C26.6001 146.6 31.1001 142.1 36.7001 142.1H109.4C116.7 142.1 122.6 136.1 122.6 128.8C122.6 121.5 116.7 115.6 109.4 115.6H37.2001C31.3001 115.6 26.6001 110.9 26.6001 105V39.5C26.6001 32.4 32.3001 26.7 39.4001 26.7H146.2C153.5 26.7 159.4 20.8 159.4 13.5Z" fill="#FF00FF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M459.6 92.2V182.3C459.6 189.6 453.7 195.5 446.4 195.5C441.5 195.5 437.3 192.8 435 188.9C434.4 189.367 433.766 189.833 433.1 190.3C422.2 198.5 408.7 203.3 394.1 203.3C358 203.3 328.8 174.1 328.8 138C328.8 101.9 358 72.7 394.1 72.7C408.7 72.7 422.2 77.5 433.1 85.7C433.566 85.9667 434 86.2667 434.4 86.6C436.5 82.1 441 78.9 446.4 78.9C453.7 78.9 459.6 84.9 459.6 92.2ZM433.1 138C433.1 116.4 415.6 98.9 394.1 98.9C372.5 98.9 355 116.4 355 138C355 159.6 372.5 177.1 394.1 177.1C415.6 177.1 433.1 159.6 433.1 138Z" fill="#FF00FF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M715.6 195.5H715.5C714.8 207.2 709.9 236.3 679.9 251.9C679.9 251.9 650.5 271 615.1 249.3C615.1 249.3 602.5 239.4 598.1 232.9C598.1 232.9 592 221.7 604.5 214.9C604.5 214.9 611.9 211.1 617.6 215.7C617.6 215.7 638.2 241.1 665.8 228.9C665.8 228.9 687.5 218.1 687.5 199.8C687.5 199.8 686.6 195.6 682.8 193.6C672.8 199.7 661 203.3 648.4 203.3C612.4 203.3 583.1 174.1 583.1 138C583.1 101.9 612.4 72.7 648.4 72.7C663.8 72.7 677.8 78 689 86.8C691.3 82.2 696.1 78.9 701.6 78.9C709.4 78.9 715.6 85.2 715.6 93V195.5ZM648.4 177.1C670 177.1 687.5 159.6 687.5 138C687.5 116.4 670 98.9 648.4 98.9C626.9 98.9 609.4 116.4 609.4 138C609.4 159.6 626.9 177.1 648.4 177.1Z" fill="#FF00FF"/>
<path d="M837.4 201.7C844.7 201.7 850.5 195.9 850.5 188.6V138.4C850.5 125.6 847.3 112.9 840.7 102C833.8 90.4 822.3 78.7 803.1 74.8C803.1 74.8 788.1 71.9 771 79.3C769.1 80.2 766.9 80.6 764.7 80.6H763.9C761.7 80.6 759.6 80.2 757.7 79.4C755.9 78.6 752.9 77.8 748.6 78C743.8 78.2 739.6 81.2 738.1 85.7C737.5 87.2 737.1 89.1 737 91.3V188.6C737 195.9 742.9 201.7 750.1 201.7H751C758.2 201.7 764.1 195.9 764.1 188.6V131C764.1 123.1 766.9 115.4 772.3 109.7C777.2 104.4 785 99.9 797.3 100.1C797.3 100.1 816.1 100.6 821.5 123.7C822.4 127.3 822.7 131 822.7 134.7V188.6C822.7 195.9 828.6 201.7 835.8 201.7H837.4Z" fill="#FF00FF"/>
<path d="M182.801 90.6714C183.801 99.8714 188.001 119.371 203.901 135.371C205.601 137.071 206.201 139.571 205.201 141.671C204.901 142.271 204.501 142.871 204.001 143.371C204.001 143.371 181.801 165.771 182.001 189.371C182.001 193.671 183.901 197.871 187.401 200.371C189.701 202.071 192.901 203.171 197.001 202.071C201.901 200.771 205.601 196.771 207.001 191.771C209.901 180.871 219.101 155.771 241.701 153.471C241.701 153.471 268.201 149.471 279.201 176.771C279.201 176.771 282.501 185.371 284.201 190.771C285.601 194.971 288.501 198.671 292.501 200.871C296.301 202.971 301.201 203.671 305.701 198.471C309.001 194.771 310.201 189.571 309.401 184.671C307.801 175.371 303.001 157.371 289.501 142.271C287.501 140.071 287.401 136.771 289.301 134.471C296.801 125.371 313.201 102.871 309.001 84.8714C306.801 75.2714 294.601 71.5714 287.801 78.7714C287.601 79.038 287.401 79.2714 287.201 79.4714C285.201 81.8714 284.001 84.7714 283.401 87.7714C281.901 95.4714 275.301 120.071 251.601 123.571C251.601 123.571 230.301 126.471 221.901 114.871C221.901 114.871 212.701 104.571 207.101 86.0714C205.701 81.3714 202.101 77.3714 197.301 75.9714C193.901 74.9714 190.001 75.1714 186.501 78.8714C183.501 82.0714 182.301 86.3714 182.801 90.6714Z" fill="url(#paint0_linear_230_897)"/>
</g>
<defs>
<linearGradient id="paint0_linear_230_897" x1="297" y1="75" x2="297" y2="179" gradientUnits="userSpaceOnUse">
<stop stopColor="#FF00FF"/>
<stop offset="1" stopColor="#519CE6"/>
</linearGradient>
<clipPath id="clip0_230_897">
<rect width="851" height="260" fill="white"/>
</clipPath>
</defs>
</svg>

              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h4"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  {localStorage.getItem("email")}
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[500]}>
                  {localStorage.getItem("role")}
                </Typography>
              </Box>
            </Box>
          )}
          {(localStorage.getItem("role") == "admin" || localStorage.getItem("role") == "superadmin") && (
            <Box paddingLeft={isCollapsed ? undefined : "10%"}>
              <Item
                title="Dashboard"
                to="/"
                icon={<HomeOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Calendar"
                to="/calendar/0"
                icon={<CalendarTodayOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
 <Item
                title="Appointment"
                to="/Appointment"
                icon={<BookOnlineIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Typography
                variant="h6"
                color={colors.grey[300]}
                sx={{ m: "15px 0 5px 20px" }}
              >
                Data
              </Typography>
              <Item
                title="Manage Team"
                to="/team"
                icon={<PeopleOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Patients"
                to="/Patient"
                icon={<AirlineSeatReclineExtraIcon />}
                selected={selected}
                setSelected={setSelected}
              />

              <Item
                title="Clinics"
                to="/Clinic"
                icon={<DocumentScannerIcon  />}
                selected={selected}
                setSelected={setSelected}
              />
    <Item
                title="Scanners"
                to="/Scanner"
                icon={<DomainIcon />}
                selected={selected}
                setSelected={setSelected}
              />

              <Item
                title="Lab"
                to="/Lab"
                icon={<PrecisionManufacturingIcon />}
                selected={selected}
                setSelected={setSelected}
              />
               <Item
                title="Printers"
                to="/Printer"
                icon={<LocalPrintshopIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            
           
 <Item
                title="Designer"
                to="/Designer"
                icon={<DesignServicesIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            
              <Typography
                variant="h6"
                color={colors.grey[300]}
                sx={{ m: "15px 0 5px 20px" }}
              >

                Mobile Application
              </Typography>
              <Item
                title="Plans"
                to="/Plan"
                icon={<ReorderIcon />}
                selected={selected}
                setSelected={setSelected}
              />
   <Item
                title="Districts"
                to="/District"
                icon={<EditLocationIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Faqs"
                to="/Faqapp"
                icon={<QuizIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Contact us"
                to="/Contactus"
                icon={<PeopleOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            </Box>
          )}
          {localStorage.getItem("role") == "clinic" && (
            <Box paddingLeft={isCollapsed ? undefined : "10%"}>
              <Item
                title="Dashboard"
                to="/"
                icon={<HomeOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Calendar"
                to={"/calendar/" + localStorage.getItem("id")}
                icon={<CalendarTodayOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
 <Item
                title="Appointment"
                to="/Appointment"
                icon={<BookOnlineIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Patient"
                to="/Patient"
                icon={<AirlineSeatReclineExtraIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            </Box>
          )}
            {localStorage.getItem("role") == "lab" && (
            <Box paddingLeft={isCollapsed ? undefined : "10%"}>
              

              <Item
                title="Patient"
                to="/Patient"
                icon={<AirlineSeatReclineExtraIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            </Box>
          )}
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
