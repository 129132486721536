import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  Treatment: [],
  addTreatment: [],
  deleteTreatment: [],
  scan : [],
  addscan : [],
  deletescan : [],
};

const TreatmentSlice = createSlice({
  name: "Treatment",
  initialState,
  reducers: {
    TreatmentRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    TreatmentSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.Treatment = action.payload;
    },

    TreatmentFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    addTreatmentRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    addTreatmentSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.addTreatment = action.payload;
    },

    addTreatmentFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  
    deleteTreatmentRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    deleteTreatmentSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.deleteTreatment = action.payload;
    },

    deleteTreatmentFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    scanRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.scan = [];
    },
    scanSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.scan = action.payload;
    },
    scanFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.scan = [];
    },
    addscanRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    addscanSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.addscan = action.payload;
    },
    addscanFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deletescanRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    deletescanSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.deletescan = action.payload;
    },
    deletescanFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    }

    
  },
});

export const TreatmentAction = TreatmentSlice.actions;
export default TreatmentSlice;
