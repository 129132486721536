import axios from "axios";
import { ClinicAction } from "./ClinicReducers";

export const getClinic = (districtId) => async (dispatch) => {
  const token = localStorage.getItem("jwtToken");

  try {
    dispatch(ClinicAction.ClinicRequest());

    const res = await axios.get(
      `${process.env.REACT_APP_API}/api/clinics${
        districtId ? `?districtId=${districtId}` : ""
      }`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch(ClinicAction.ClinicSuccess(res));
  } catch (error) {
    dispatch(ClinicAction.ClinicFail(error));
  }
};

export const AddClinic =
  (
    email,
    phone_number,
    district_id,
    name,
    doctor_name,
    doctor_phone_number,
    doctor_email,
    address,
    secretary_name,
    secretary_phone_number,
    secretary_email,
    location,
    landline_number,
    website,
    facebook_page,
    instagram_page
  ) =>
  async (dispatch) => {
    dispatch(ClinicAction.addClinicRequest());
    const token = localStorage.getItem("jwtToken");
    try {
      await axios.post(
        process.env.REACT_APP_API + "/api/clinics",
        {
          email,
          phone_number,
          district_id,
          name,
          doctor_name,
          doctor_phone_number,
          doctor_email,
          address,
          secretary_name,
          secretary_phone_number,
          secretary_email,
          location,
          landline_number,
          website,
          facebook_page,
          instagram_page,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(ClinicAction.addClinicSuccess("success"));
      dispatch(getClinic());
    } catch (e) {
      dispatch(ClinicAction.addClinicFail(e.response.data));
    }
  };

export const updateClinic =
  (
    id,
    email,
    phone_number,
    district_id,
    name,
    doctor_name,
    doctor_phone_number,
    doctor_email,
    address,
    secretary_name,
    secretary_phone_number,
    secretary_email,
    location,
    landline_number,
    website,
    facebook_page,
    instagram_page
  ) =>
  async (dispatch) => {
    dispatch(ClinicAction.updateClinicRequest());
    const token = localStorage.getItem("jwtToken");
    try {
      await axios.put(
        process.env.REACT_APP_API + "/api/clinics/" + id,
        {
          id,

          name,
          doctor_name,
          doctor_phone_number,
          doctor_email,
          address,
          secretary_name,
          secretary_phone_number,
          secretary_email,
          location,
          landline_number,
          website,
          facebook_page,
          instagram_page,
          user: {
            email,
            phone_number,
            district_id,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(ClinicAction.updateClinicSuccess());
      dispatch(getClinic());
    } catch (e) {
      dispatch(ClinicAction.updateClinicFail(e.response.data));
    }
  };
export const deleteClinic = (id) => async (dispatch) => {
  dispatch(ClinicAction.deleteClinicRequest());
  const token = localStorage.getItem("jwtToken");
  try {
    //id?
    await axios.delete(process.env.REACT_APP_API + "/api/clinics/" + id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(ClinicAction.deleteClinicSuccess());
    dispatch(getClinic());
  } catch (e) {
    dispatch(ClinicAction.deleteClinicFail(e.response.data));
  }
};
export const getclinicbyid = (id) => async (dispatch) => {
  const token = localStorage.getItem("jwtToken");

  try {
    dispatch(ClinicAction.ClinicidRequest());

    const res = await axios.get(process.env.REACT_APP_API + "/api/clinics/" + id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch(ClinicAction.ClinicidSuccess(res));
  } catch (error) {
    dispatch(ClinicAction.ClinicidFail(error));
  }
};