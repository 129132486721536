import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  Team: [],
  addTeam: [],
  updateTeam: [],
  deleteTeam: [],
};

const TeamSlice = createSlice({
  name: "Team",
  initialState,
  reducers: {
    TeamRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },

    TeamFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    TeamSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.Team = action.payload;
    },
    AddTeamSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.addTeam = action.payload;
    },
    deleteSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.deleteTeam = action.payload;
    },
  },
});

export const TeamAction = TeamSlice.actions;
export default TeamSlice;
