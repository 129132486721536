import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  printer: [],
  addPrinter: [],
  updatePrinter: [],
  deletePrinter: [],
};

const PrinterSlice = createSlice({
  name: "printer",
  initialState,
  reducers: {
    printerRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    printerSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.printer = action.payload;
    },

    printerFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    addPrinterRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    addPrinterSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.addPrinter = action.payload;
    },

    addPrinterFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updatePrinterRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    updatePrinterSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.updatePrinter = action.payload;
    },

    updatePrinterFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deletePrinterRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    deletePrinterSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.deletePrinter = action.payload;
    },

    deletePrinterFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const PrinterAction = PrinterSlice.actions;
export default PrinterSlice;
