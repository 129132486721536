import { combineReducers } from "redux";
import PlanSlice from "./Plan/PlanReducers";
import FaqSlice from "./Faq/FaqReducers";
import ContactSlice from "./Contactus/ContactReducers";
import DistrictSlice from "./District/DistrictReducers";
import PatientSlice from "./Patient/PatientReducers";
import ClinicSlice from "./Clinic/ClinicReducers";
import TeamSlice from "./Team/TeamReducers";
import AppointmentSlice from "./Appointment/AppointmentReducers";
import AuthSlice from "./Auth/AuthReducers";
import LabSlice from "./Lab/LabReducers";
import DesignerSlice from "./Designer/DesignerReducers";
import PrinterSlice from "./Printer/PrinterReducers";
import ScannerSlice from "./Scanner/ScannerReducers";
import TreatmentSlice from "./Treatment/TreatmentReducers";

const RootReducer = combineReducers({
  Plan: PlanSlice.reducer,
  Faq: FaqSlice.reducer,
  Contact: ContactSlice.reducer,
  Patient: PatientSlice.reducer,
  District: DistrictSlice.reducer,
  Clinic: ClinicSlice.reducer,
  Appointment: AppointmentSlice.reducer,
  Team: TeamSlice.reducer,
  Auth: AuthSlice.reducer,
  Lab: LabSlice.reducer,
  Designer: DesignerSlice.reducer,
  Printer: PrinterSlice.reducer,
  Scanner: ScannerSlice.reducer,
  Treatment: TreatmentSlice.reducer,
});

export default RootReducer;
