import axios from "axios";
import { TeamAction } from "./TeamReducers";

export const getTeam = () => async (dispatch) => {
  try {
    dispatch(TeamAction.TeamRequest());

    const res = await axios.get(process.env.REACT_APP_API + "/api/users");

    dispatch(TeamAction.TeamSuccess(res));
  } catch (error) {
    dispatch(TeamAction.TeamFail(error));
  }
};
export const AddTeammember =
  (email, phone_number, district_id) =>
  async (dispatch) => {
    dispatch(TeamAction.TeamRequest());
    const token = localStorage.getItem("jwtToken");
    try {
      await axios.post(
        process.env.REACT_APP_API + "/api/create-admin",
        {
          email,    
          phone_number,
          district_id,
        
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(TeamAction.AddTeamSuccess());
      dispatch(getTeam());
    } catch (e) {
      dispatch(TeamAction.TeamFail(e.response.data));
    }
  };

  export const deleteadmin = (id) => async (dispatch) => {
    try {
      dispatch(TeamAction.TeamRequest());
  
      const res = await axios.delete(process.env.REACT_APP_API + "/api/users/" +id);
  
      dispatch(TeamAction.deleteSuccess(res));
      dispatch(getTeam());
    } catch (error) {
      dispatch(TeamAction.TeamFail(error));
    }
  };