import React, { useState } from "react";

const Anterior = () => {
  const [maintainChecked1, setMaintainChecked1] = useState(false);
  const [maintainChecked2, setMaintainChecked2] = useState(false);
  const [maintainChecked3, setMaintainChecked3] = useState(false);
  const [maintainChecked4, setMaintainChecked4] = useState(false);
  const [maintainChecked5, setMaintainChecked5] = useState(false);

  return (
    <div
      style={{
        padding: "15px",
        borderRadius: "5px",
        marginTop: "20px",
        color: "black",
      }}
    >
      <h3 style={{ fontSize: "1.2rem", marginBottom: "0.5rem" }}>Anterior</h3>
      {[
        "Maintain",
        "Improve canine relationship only",
        "Improve canine & molar relationship up to 4 mm",
        "Correction to Class I (canine & molar)",
        "Distalisation (up to 2 mm, without elastics)",
      ].map((item, index) => {
        const maintainChecked = [
          maintainChecked1,
          maintainChecked2,
          maintainChecked3,
          maintainChecked4,
          maintainChecked5,
        ][index];
        const setMaintainChecked = [
          setMaintainChecked1,
          setMaintainChecked2,
          setMaintainChecked3,
          setMaintainChecked4,
          setMaintainChecked5,
        ][index];

        return (
          <div key={index} style={{ marginBottom: "0.5rem" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                fontSize: "0.9rem",
              }}
            >
              <div style={{ marginRight: "0.5rem" }}>
                <input
                  type="checkbox"
                  id={`maintain-${index}`}
                  name={`maintain-${index}`}
                  checked={maintainChecked}
                  onChange={() => setMaintainChecked(!maintainChecked)}
                  style={{
                    width: "1.2rem",
                    height: "1.2rem",
                    marginRight: "0.5rem",
                    cursor: "pointer",
                  }}
                />
                {maintainChecked && (
                  <span style={{ fontSize: "1.2rem" }}>&times;</span>
                )}
                <label htmlFor={`maintain-${index}`}>{item}</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  id={`right-${index}`}
                  name={`right-${index}`}
                  disabled={!maintainChecked}
                />
                <label htmlFor={`right-${index}`}>Right</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  id={`left-${index}`}
                  name={`left-${index}`}
                  disabled={!maintainChecked}
                />
                <label htmlFor={`left-${index}`}>Left</label>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Anterior;
