import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  Lab: [],
  addLab: [],
  updateLab: [],
  deleteLab: [],
};

const LabSlice = createSlice({
  name: "Lab",
  initialState,
  reducers: {
    LabRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    LabSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.Lab = action.payload;
    },

    LabFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    addLabRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    addLabSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.addLab = action.payload;
    },

    addLabFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateLabRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    updateLabSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.updateLab = action.payload;
    },

    updateLabFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteLabRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    deleteLabSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.deleteLab = action.payload;
    },

    deleteLabFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const LabAction = LabSlice.actions;
export default LabSlice;
