import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  plan: [],
  addplan: [],
  updateplan: [],
  deleteplan: [],
};

const PlanSlice = createSlice({
  name: "plan",
  initialState,
  reducers: {
    planRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    planSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.plan = action.payload;
    },

    planFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    addplanRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    addplanSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.addplan = action.payload;
    },

    addplanFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateplanRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    updateplanSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.updateplan = action.payload;
    },

    updateplanFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteplanRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    deleteplanSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.deleteplan = action.payload;
    },

    deleteplanFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const PlanAction = PlanSlice.actions;
export default PlanSlice;
